@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Open+Sans:wght@400;700&display=swap');      
      
body {      
  margin: 0;      
  font-family: 'Open Sans', sans-serif;      
  -webkit-font-smoothing: antialiased;      
  -moz-osx-font-smoothing: grayscale;      
  background: #f0f0f0;    
}      
      
.containerWithPerspective {      
  perspective: 1000px;      
}      
      
.cardWithPreserve3d {      
  position: relative;      
  transform-style: preserve-3d;      
  width: 100%;    
  height: 100%;    
  border: 0px;    
}      
      
.card-content, .card-content-back {      
  position: absolute;      
  width: 100%;      
  height: 100%;      
  -webkit-backface-visibility: hidden;    
  backface-visibility: hidden;    
  -webkit-transform: rotateY(0deg);    
  transform: rotateY(0deg);    
  display: flex;    
  align-items: center;    
  justify-content: center;    
}      
      
.card-content-back {      
  -webkit-transform: rotateY(180deg);    
  transform: rotateY(180deg);    
}      

@keyframes slideInFromLeft {    
  from {    
    transform: translateX(-100vw); /* Flytter elementet 100% af viewportens bredde til venstre */    
    opacity: 0;    
  }    
  to {    
    transform: translateX(0);    
    opacity: 1;    
  }    
}    
  
      
.flip-animation {          
  animation: flipCard 0.5s ease-out forwards;          
  transform-style: preserve-3d;    
  animation-fill-mode: forwards;       
}        
      
@keyframes flipCard {          
  0% {          
    transform: rotateY(0deg);          
    opacity: 1;    
  }          
  45% {    
    opacity: 1;    
  }    
  55% {      
    transform: rotateY(90deg);      
    opacity: 0;      
  }      
  100% {          
    transform: rotateY(0deg);          
    opacity: 1;      
  }          
}     